import React from "react";
import Seo from "components/seo";
import Layout from "components/layout";
import Banner from "sections/banner";
import Services from "sections/services";
import Support from "sections/support-team";
import Pricing from "sections/pricing";

export default function IndexPage() {
  return (
    <Layout>
      <Seo
        title="RCSI COLLAB"
        description="Vous êtes syndic bénévole ou coopératif n'attendez plus gérer votre copropriété avec COLLAB"
      />
      <Banner />
      <Services />
      <Pricing />
      <Support />
    </Layout>
  );
}
