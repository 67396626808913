/** @jsx jsx */
import { jsx, Box, Image, Heading } from "theme-ui";

const Feature = ({ data, ...props }) => {
  return (
    <Box sx={styles.feature} {...props}>
      <Box as="figure">
        <Image src={data?.icon} alt={data?.title} />
      </Box>
      <Box sx={styles.box}>
        <Heading as="h4">{data?.title}</Heading>
      </Box>
    </Box>
  );
};

export default Feature;

const styles = {
  box: {
    display: "flex",
    alignItems: "center",
    justifyContent: ["center", null, null, null, null, null],
    paddingBottom: "3px",
  },
  feature: {
    display: [null, null, null, null, null, "flex"],
    textAlign: ["center", null, null, null, null, "left"],
    px: [3, null, null, null, "unset"],
    figure: {
      minWidth: [42, null, null, 70],
      mr: [null, null, null, null, null, 6],
      mb: [3, null, null, null, null, 0],
      img: {
        mx: ["auto"],
      },
    },
    h4: {
      fontSize: [2, null, null, 3],
      lineHeight: 1.28,
      color: "heading",
      textAlign: ["center", null, null, null, null, "left"],
    },
    p: {
      fontSize: [1, null, null, 2],
      lineHeight: 1.88,
      color: "text",
    },
    a: {
      mt: [3],
    },
  },
};
