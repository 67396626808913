/** @jsxRuntime classic */
/** @jsx jsx */
import Slider from "react-slick";
import { jsx, Box, Container } from "theme-ui";
import { useMediaQuery } from "react-responsive";
import PriceTable from "components/cards/price-table";
import SectionHeading from "components/section-heading";

import "assets/css/react-slick.css";

const data = [
  {
    id: 1,
    title: "FORMULE 1",
    role1: "PRESIDENT: CREATION + GESTION",
    amount1: 35,
    annual_amount1: 385,
    month_decreased1: 1,
    role2: "COPROPRIETAIRES: CREATION DU COMPTE + VISUALISATION",
    amount2: 20,
    annual_amount2: 200,
    month_decreased2: 2,
    item1: true,
    item2: true,
    item3: false,
    item4: false,
    item5: false,
    item6: false,
    item7: false,
    item8: false,
    item9: false,
    item10: false,
    item11: false,
    item12: false,
    trial_period: 7,
    is_recommended: false,
  },
  {
    id: 2,
    title: "FORMULE 2",
    role1: "PRESIDENT: CREATION + GESTION",
    amount1: 50,
    annual_amount1: 550,
    month_decreased1: 1,
    role2: "COPROPRIETAIRES: CREATION DU COMPTE + VISUALISATION",
    amount2: 35,
    annual_amount2: 385,
    month_decreased2: 1,
    item1: true,
    item2: true,
    item3: true,
    item4: true,
    item5: true,
    item6: true,
    item7: true,
    item8: true,
    item9: true,
    item10: true,
    item11: true,
    item12: true,
    trial_period: 7,
    is_recommended: true,
  },
];

const settings = {
  // slidesToShow: 3,
  // slidesToScroll: 1,
  arrows: false,
  dots: true,
  speed: 500,
  responsive: [
    {
      breakpoint: 100000,
      settings: "unslick",
    },
    {
      breakpoint: 768,
      settings: {
        // fade: true,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        fade: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Pricing = () => {
  const isTablet = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  return (
    <section id="pricing" sx={styles.section}>
      <Container>
        <SectionHeading
          sx={{ mb: [6, null, null, 14] }}
          slogan="Offre pour votre copropriété"
          title="Découvrez nos formules et tarifs"
        />
        <Box sx={styles.grid}>
          {isTablet && (
            <ul sx={styles.features}>
              <li>Accès au logiciel</li>
              <li>Accompagnement à la bonne gestion</li>
              <li>Conseils illimités par nos comptables syndics</li>
              <li>Consultation téléphonique en comptabilité syndic</li>
              <li>Accès a notre base de données d'entreprise</li>
              <li>
                Mise à disposition des documents pour démarrer vote activité
                syndic bénévoles
              </li>
              <li>Convocation d'ag type</li>
              <li>Mandat de syndic bénévole</li>
              <li>Fiche de renseignement</li>
              <li>Vos 5 annexes vierges</li>
              <li>Un relevé général des dépenses type prêt à être remplis</li>
              <li>Rédaction de votre PV d'ag</li>
            </ul>
          )}

          <Slider sx={styles.priceGroup} {...settings}>
            {data.map((price) => (
              <PriceTable key={price.id} data={price} />
            ))}
          </Slider>
        </Box>
      </Container>
    </section>
  );
};

export default Pricing;

const styles = {
  section: {
    pt: [6, null, 0, null, 10, 12],
    pb: [8, null, null, null, 0, 0],
  },
  grid: {
    maxWidth: 1133,
    display: [null, null, null, null, "grid"],
    alignItems: [null, null, null, null, "center"],
    justifyContent: [null, null, null, null, "center"],
    gridTemplateColumns: [null, null, null, null, "1fr 1.9fr"],
    mx: "auto",
    ".slick-list": {
      paddingTop: ["31px", null, null, null, 0],
    },
    ".slick-dots": {
      margin: 0,
      padding: 0,
      listStyle: "none",
      display: "flex !important",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 5,
      li: {
        display: "flex",
      },
      button: {
        backgroundColor: "#CED7E1",
        border: 0,
        outline: 0,
        padding: 0,
        margin: "0 3.5px",
        width: 10,
        height: 10,
        borderRadius: "50%",
        overflow: "hidden",
        textIndent: "-9999rem",
        transition: "all 0.3s ease-in-out 0s",
      },
      ".slick-active button": {
        backgroundColor: "heading",
        width: 13,
        height: 13,
      },
    },
  },
  priceGroup: {
    gap: 2,
    alignItems: "flex-end",
    display: [null, null, null, null, "grid"],
    gridTemplateColumns: [null, null, null, null, "repeat(2, 1fr)"],
  },
  features: {
    m: 0,
    p: 0,
    mt: ["20px", null, null, null, null, "163px"],
    listStyle: "none",
    transform: [
      null,
      null,
      null,
      null,
      "translateY(-10px)",
      "translateY(-22px)",
    ],
    li: {
      display: "flex",
      alignItems: "center",
      fontWeight: 500,
      minHeight: [null, null, null, null, 50, 59],
      "+ li": {
        borderTop: (t) => `1px solid ${t.colors.borderColor}`,
      },
    },
  },
};
