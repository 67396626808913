/** @jsx jsx */
import { rgba } from "polished";
import { jsx, Box, Button } from "theme-ui";
import { IoMdCheckmarkCircle, IoIosCloseCircle } from "react-icons/io";

const FeatureIcon = ({ value }) => {
  return value ? (
    <IoMdCheckmarkCircle size="23px" color="#3CC68A" />
  ) : (
    <IoIosCloseCircle size="23px" color="#CED7E1" />
  );
};

const PriceTable = ({ data }) => {
  return (
    <div sx={styles.card} className={data.is_recommended ? "recommended" : ""}>
      {data.is_recommended && <span sx={styles.recommended}>Recommandé</span>}
      <div
        sx={styles.priceTable}
        className={data.is_recommended ? "recommended" : ""}
      >
        <span sx={styles.title}>{data.title}</span>
        <div sx={styles.role}>
          <span sx={styles.role.title}>{data.role1}</span>
          <div sx={styles.smallText}>
            Paiement mensuel: {data.amount1}
            <small>€ / MOIS</small>
          </div>
          <div>
            Paiement annuel: {data.annual_amount1}
            <small>€ / AN</small> ({data.month_decreased1} mois gratuit)
          </div>
        </div>
        <div sx={styles.role}>
          <span sx={styles.role.title}>{data.role2}</span>
          <div sx={styles.smallText}>
            Paiement mensuel: {data.amount2}
            <small>€ / MOIS</small>
          </div>
          <div>
            Paiement annuel: {data.annual_amount2}
            <small>€ / AN</small> ({data.month_decreased2} mois gratuit)
          </div>
        </div>
        <Box as="ul" variant="styles.unStyled" sx={styles.features}>
          <li>
            <span>Accès au logiciel</span>
            <FeatureIcon value={data.item1} />
          </li>
          <li>
            <span>Accompagnement à la bonne gestion</span>
            <FeatureIcon value={data.item2} />
          </li>
          <li>
            <span>Conseils illimités par nos comptables syndics</span>
            <FeatureIcon value={data.item3} />
          </li>
          <li>
            <span>Consultation téléphonique en comptabilité syndic</span>
            <FeatureIcon value={data.item4} />
          </li>
          <li>
            <span>Accès a notre base de données d'entreprise</span>
            <FeatureIcon value={data.item5} />
          </li>
          <li>
            <span>
              Mise à disposition des documents pour démarrer vote activité
              syndic bénévoles
            </span>
            <FeatureIcon value={data.item6} />
          </li>
          <li>
            <span>Convocation d'ag type</span>
            <FeatureIcon value={data.item7} />
          </li>
          <li>
            <span>Mandat de syndic bénévole</span>
            <FeatureIcon value={data.item8} />
          </li>
          <li>
            <span>Fiche de renseignement</span>
            <FeatureIcon value={data.item9} />
          </li>
          <li>
            <span>Vos 5 annexes vierges</span>
            <FeatureIcon value={data.item10} />
          </li>
          <li>
            <span>Un relevé général des dépenses type prêt à être remplis</span>
            <FeatureIcon value={data.item11} />
          </li>
          <li>
            <span>Rédaction de votre PV d'ag</span>
            <FeatureIcon value={data.item12} />
          </li>
        </Box>
        <Button
          sx={styles.button}
          variant="primaryOutline"
          className={data.is_recommended ? "recommended" : ""}
          onClick={() =>
            window
              .open(
                `https://app.rcsi-collab.com/register${
                  data.id === 1 ? "" : `?formule=${data.id}`
                }`,
                "_blank"
              )
              .focus()
          }
        >
          Choisir cette formule
        </Button>
        <span sx={styles.trial}>
          Essai gratuit de {data.trial_period} jours
        </span>
      </div>
    </div>
  );
};

export default PriceTable;

const styles = {
  smallText: {
    fontSize: "16px",
  },
  role: {
    height: "100px",
    px: [2],
    py: [4],
    title: {
      color: rgba("#0F2137", 0.5),
      display: "block",
      fontWeight: 500,
      fontSize: [1, null, null, 2],
      lineHeight: 1.31,
    },
  },
  card: {
    position: "relative",
    textAlign: "center",
    "&.recommended": {
      borderColor: [null, null, null, null, "white"],
      borderRadius: "0px 0px 10px 10px",
      boxShadow: [
        null,
        null,
        null,
        null,
        "0px 15px 50px rgba(91, 132, 193, 0.1)",
      ],
      priceTable: {
        borderRadius: "0 0 10px 10px",
      },
    },
    mx: [null, null, null, 3, 0],
  },
  priceTable: {
    border: (t) => `1px solid ${t.colors.borderColor}`,
    borderRadius: 10,
    paddingTop: [4, null, null, null, 6],
    paddingBottom: [4, null, null, null, 6, 14],
    "&.recommended": {
      borderColor: [null, null, null, null, "white"],
      borderRadius: "0 0 10px 10px",
    },
  },
  recommended: {
    backgroundColor: "#52ACFF",
    borderRadius: "8px 8px 0px 0px",
    color: "white",
    fontSize: 1,
    fontWeight: 700,
    lineHeight: 1.29,
    position: "absolute",
    width: "100%",
    minHeight: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: ["-30px", null, null, null, "-31px"],
    textTransform: "uppercase",
  },
  title: {
    display: "block",
    fontSize: [1, null, null, 5],
    lineHeight: 1.31,
    mb: [3, null, null, 5, 2, 5],
  },
  amount: {
    color: "heading",
    display: "block",
    fontWeight: "500",
    fontSize: [4, null, null, 5, 3, 5],
    lineHeight: [1.3, null, null, 1.71],
    letterSpacing: "heading",
    small: {
      fontSize: [2, null, null, 4, 2, 4],
      fontWeight: 400,
    },
  },
  features: {
    my: [4, null, null, 8, 2, 8],
    li: {
      alignItems: "center",
      borderTop: (t) => `1px solid ${t.colors.borderColor}`,
      display: "flex",
      color: ["heading", null, null, null, "headingSecondary"],
      fontSize: [1, null, null, null, 2],
      fontWeight: [500, null, null, null, 400],
      justifyContent: ["space-between", null, null, null, "center"],
      px: [6, null, null, null, 0],
      minHeight: [null, null, null, null, 50, 59],
      pt: [3, null, null, null, 0],
      pb: [3, null, null, null, 0],
      ":first-of-type": {
        borderTop: 0,
        pt: 0,
      },
      ":last-of-type": {
        pb: 0,
      },
      span: {
        display: [null, null, null, null, "none"],
      },
      svg: {
        height: 20,
        width: 20,
      },
    },
  },
  button: {
    minHeight: [30, null, null, 45, 40, 50],
    padding: ["0 20px", null, null, "0 40px", "0 20px", "0 40px"],
    fontSize: [1, null, null, 2, 1, 2],
    ".recommended": {
      backgroundColor: "secondary",
      color: "white",
      ":hover": {
        backgroundColor: "dark",
      },
    },
  },
  trial: {
    display: "block",
    color: rgba("#0F2137", 0.5),
    fontSize: ["13px", null, null, "15px"],
    lineHeight: 1.33,
    mt: [3, null, null, 5],
  },
};
