/** @jsx jsx */
import { jsx, Box, Grid, Container, Button, Heading, Text } from "theme-ui";
import { rgba } from "polished";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import Image from "components/image";

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "banner.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderImage: file(relativePath: { eq: "background.png" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);

  const image = getImage(data && data.placeholderImage);

  // Use like this:
  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage
      Tag="section"
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      style={{ img: { opacity: 0.5 } }}
    >
      <Container sx={styles.container}>
        <Grid sx={styles.grid}>
          <Box sx={styles.content}>
            <Heading as="h1">
              Vous êtes syndic bénévole ou coopératif n'attendez plus gérer
              votre copropriété avec COLLAB
            </Heading>
            <Text as="p">
              Collab, votre nouveau logiciel de gestion de copropriété pour
              syndic benevoles et coopératifs
            </Text>
            <div sx={styles.buttonGroup}>
              <Button
                variant="primary"
                onClick={() =>
                  window
                    .open("https://app.rcsi-collab.com/register", "_blank")
                    .focus()
                }
              >
                Profiter d'un essai gratuit de 7 jours
              </Button>
              <Button
                variant="white"
                className="white"
                onClick={() =>
                  window
                    .open("https://app.rcsi-collab.com/register", "_blank")
                    .focus()
                }
              >
                S'inscrire
              </Button>
            </div>
          </Box>
          <Box as="figure" sx={styles.illustration}>
            <Image
              sx={styles.illustrationImage}
              src={data.illustration.childImageSharp.fluid}
              alt="illustration"
            />
          </Box>
        </Grid>
      </Container>
    </BackgroundImage>
  );
};

export default Banner;

const styles = {
  section: {
    pt: [8, null, null, null, 10, 0],
    pb: [8, null, null, null, 10, 0],
  },
  container: {
    px: [3, null, null, 6],
  },
  grid: {
    alignItems: ["center"],
    gridTemplateColumns: ["1fr", null, null, null, "1fr 1.1fr"],
    minHeight: [null, null, null, null, "45vh", "100vh"],
    pt: [15, null, null, null, 17, 12],
    pb: 15,
  },
  content: {
    paddingBottom: "50px",
    textAlign: ["center", null, null, null, "left"],
    h1: {
      color: "textSecondary",
      fontWeight: "bold",
      fontSize: [8, null, null, null, 10, 11, 11],
      lineHeight: [1.33, null, null, null, 1.36],
      letterSpacing: "heading",
    },
    p: {
      color: "textSecondary",
      fontSize: [1, null, null, 2],
      lineHeight: 1.75,
      maxWidth: 480,
      mx: [null, null, null, "auto", "unset"],
      marginTop: [1, null, null, 3],
    },
  },
  newsUpdate: {
    backgroundColor: "white",
    boxShadow: "0px 2px 8px rgba(67, 99, 136, 0.07)",
    borderRadius: 50,
    padding: "4px 15px 4px 5px",
    display: "inline-flex",
    alignItems: "center",
    color: rgba("#02073E", 0.9),
    fontSize: ["12px", null, null, 1],
    mb: 4,
    textAlign: "left",
    span: {
      backgroundColor: "primary",
      color: "white",
      fontSize: ["10px", null, null, "13px"],
      fontWeight: 700,
      display: "inline-flex",
      minHeight: 24,
      alignItems: "center",
      px: "11px",
      borderRadius: 50,
      mr: "12px",
    },
  },
  buttonGroup: {
    mt: [6, null, null, null, 10],
    button: {
      minHeight: 45,
      px: ["17px", 4],
    },
    ".white": {
      boxShadow: "0px 4px 6px rgba(125, 128, 170, 0.08)",
      borderRadius: 5,
      fontWeight: 500,
      ml: 3,
    },
  },
  illustration: {
    maxWidth: [null, null, null, "80%", "none"],
    mx: [null, null, null, "auto", "unset"],
    mt: [6, null, null, null, 0],
  },
  illustrationImage: {
    borderRadius: 10,
    height: 550,
  },
};
